import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import FaqLayout from "../../components/faq-layout"
import locale from "../../locale/en"
import DisposableGlovesContent from "../../components/faq-content/disposable-gloves"

const { faq } = locale

const DisposableGloves = () => (
  <Layout>
    <SEO title={faq.disposable_gloves} />
    <FaqLayout title={faq.disposable_gloves}>
      <DisposableGlovesContent />
    </FaqLayout>
  </Layout>
)

export default DisposableGloves
